import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Home from '../containers/home';
import TopMenu from '../components/topMenu';
import { useStaticQuery, graphql } from 'gatsby';

const IndexPage = () => {
    
      const data = useStaticQuery(graphql`
    query logowhiteSearch {
      whiterLogo: file(relativePath: { eq: "VV.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
}
 `);
    
  return (
     
    <Layout>                           
      <SEO title="Vivo Vibe" />
       <TopMenu image={data.whiterLogo}
                        >
      </TopMenu>
      <Home />
    </Layout>
  );
};

export default IndexPage;
