import React from 'react';
import PropTypes from 'prop-types';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
// import Img from 'gatsby-image';
// import { useStaticQuery, graphql } from 'gatsby';
import VivoVideo from '../assets/videos/vv-v1-2.mp4';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Logo from '../components/logo';
import LogoDisplay from '../components/logoDisplay';
import TopMenu from '../components/topMenu';
import ImgLogoDisplay from '../components/imgLogoDisplay';


const Home = ({ className }) => {
        
  const data = useStaticQuery(graphql`
    query logoSearch {
      whiterLogo: file(relativePath: { eq: "VV.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
          whitelogo: file(relativePath: { eq: "vv-white-newwater.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
    
    
    
    return (

      <section className="h-screen w-screen">

    <div className="absolute w-screen h-screen">
          <video
            autoPlay
            muted
            playsInline
            loop
            className="object-cover h-full w-full absolute"
          >
            <source src={VivoVideo} type="video/mp4" />
          </video>
       
    <div className="w-full h-full bg-black opacity-50 absolute">
        <div className="relative flex h-screen w-screen justify-center flex-col">
 <div className="daveshouse"><ImgLogoDisplay
                        image={data.whitelogo}
                        >
                        </ImgLogoDisplay>
        </div></div>
        
        </div>

        </div>
       


        <div className="relative flex w-screen flex-col fine-then ">
        
        <div class="flex content-end flex-wrap text-white text-3xl font-bold h-48 pb-16 vivo">
  <div class="w-1/3 p-2">
    <div class="text-center p-5"><AniLink paintDrip to="/why" hex="#4299e1">Why<br /> Vivo Vibe</AniLink></div>
  </div>
  <div class="w-1/3 p-2">
    <div class="text-center p-5"><AniLink paintDrip to="/login-new" hex="#4299e1">Vivo Vibe<br /> LOGIN</AniLink></div>
  </div>
          <div class="w-1/3 p-2">
    <div class="text-center p-5"><AniLink paintDrip to="/connect" hex="#4299e1">Join<br /> Vivo Vibe</AniLink></div>
  </div>
        </div>
        

            </div>
      </section>
  );
};

Home.propTypes = {
  className: PropTypes.string,
};

Home.defaultProps = {
  className: '',
};

export default Home;
